@import '~@ywfe/ywd/lib/styles/global.less';
@import './bizchart.reset.less';

@font-face {
  font-family: 'DINAlternateBold';
  src: url('https://cdn.ywwl.com/libs/fonts/DINAlternateBold.ttf');
}

.dianhu {
  .ant-layout .ant-layout {
    padding: 12px 0 0;
    .ant-layout-content > div {
      background: #f4f6fc;
      padding: 24px;
      min-height: calc(100vh - 36px - 12px);
      &.ant-tabs {
        background: #fff;
        min-height: 36px;
        padding: 0;
        .ant-tabs-nav {
          padding: 0 12px;
        }
      }
    }
    .ant-tabs-top > .ant-tabs-nav,
    .ant-tabs-bottom > .ant-tabs-nav,
    .ant-tabs-top > div > .ant-tabs-nav,
    .ant-tabs-bottom > div > .ant-tabs-nav {
      margin: 0;
    }
  }

  // 滚动条
  ::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: #c2c2c2;
    border-radius: 6px;
    background-clip: padding-box;
    min-height: 28px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a0;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
}

// tab自定义样式
.ant-tabs.huTabs {
  .ant-tabs-nav::before {
    border: none;
  }

  .ant-tabs-tab {
    padding: 13px 16px;
    font-size: 14px;
    line-height: 14px;
    color: #666;
    font-weight: 400;
    margin: 0;

    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #000;
      font-weight: 600;
    }
  }

  .ant-tabs-ink-bar {
    background: transparent;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 20px;
      height: 2px;
      background: #1890ff;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
// popover
.ant-popover.huPopover {
  .ant-popover-inner-content {
    padding: 12px 16px;
  }
  p {
    margin: 0;
  }
}
// 滚动条
.huScrollbar {
  ::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(24, 144, 255, 0.3);
    border-radius: 6px;
    background-clip: padding-box;
    min-height: 28px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a0;
  }
}

/* Define multiple global styles */
// :global {
// }
