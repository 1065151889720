.g2-tooltip {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  color: rgb(87, 87, 87);
  font-size: 12px;
  line-height: 20px;
  padding: 10px 10px 6px 10px;
  box-shadow: 0 0 10px #aeaeae;
  pointer-events: none;
}

.g2-tooltip-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.g2-tooltip-value {
  margin-left: 30px;
  display: inline;
  float: right;
}

.g2-tooltip-tail {
  background-color: #f9f9f9;
  color: #909090;
  font-size: 14px;
  padding-bottom: 10px;
  margin-top: 10px;
  list-style-type: none;
  text-align: center;
  padding-top: 10px;
}

.g2-tooltip-story {
  color: #9b9b9b;
  font-size: 12px;
  padding-bottom: 10px;
  margin-top: 10px;
  list-style-type: none;
  padding-top: 10px;
}
